import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table } from 'antd';
import { API_URL } from '../config';

const StandScansTable = () => {
  const [stands, setStands] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStands = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/stands-scans`);
        setStands(response.data);
      } catch (error) {
        console.error('Error fetching stands and scans:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStands();
  }, []);

  const expandedRowRender = (record) => {
    const columns = [
      { title: 'ID', dataIndex: 'id', key: 'id' },
      { title: 'Folio', dataIndex: ['registro', 'folio'], key: 'folio' },
      { title: 'Nombre', dataIndex: ['registro', 'nombre'], key: 'nombre' },
      { title: 'Apellido Paterno', dataIndex: ['registro', 'ap_paterno'], key: 'ap_paterno' },
      { title: 'Apellido Materno', dataIndex: ['registro', 'ap_materno'], key: 'ap_materno' },
      { title: 'Empresa', dataIndex: ['registro', 'empresa'], key: 'empresa' },
      { title: 'Correo', dataIndex: ['registro', 'correo'], key: 'corre' },
      { title: 'Telefono', dataIndex: ['registro', 'telefono'], key: 'telefono' },
      { title: 'Fecha', dataIndex: 'created_at', key: 'created_at' },
    ];

    return <Table columns={columns} dataSource={record.scans} rowKey="id" pagination={false} />;
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Nombre', dataIndex: 'name', key: 'name' },
    { title: 'Escaneos', dataIndex: 'scans', key: 'scans_count', render: (scans) => scans.length, },
  ];

  return (
    <div>
      <h1>Stands y Escaneos</h1>
      <Table
        dataSource={stands}
        columns={columns}
        rowKey="id"
        loading={loading}
        expandedRowRender={expandedRowRender}
      />
    </div>
  );
};

export default StandScansTable;
