import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { API_URL } from '../config';

const AddStandForm = ({ onStandAdded, standData }) => {
  const [form] = Form.useForm();
  const [logoFile, setLogoFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      name: standData?.name,
      logo: standData?.logo,
    });
  }, [form, standData]);

  const beforeUpload = (file) => {
    setLogoFile(file);
    return false;
  };

  const handleSubmit = async (values) => {
    setSubmitting(true);
    const formData = new FormData();
    formData.append('name', values.name);
  
    if (logoFile || standData) {
      if (logoFile) {
        formData.append('logo', logoFile);
      } else if (standData && standData.logo) {
        formData.append('logo', standData.logo);
      }
    } else {
      message.error('El logo es obligatorio');
      setSubmitting(false);
      return;
    }
  
    const requestMethod = standData ? axios.put : axios.post;
    const requestUrl = standData
      ? `${API_URL}/stands/${standData.id}`
      : `${API_URL}/stands`;
  
    try {
      await requestMethod(requestUrl, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      form.resetFields();
      setLogoFile(null);
      message.success(standData ? 'Stand actualizado correctamente' : 'Stand agregado correctamente');
      onStandAdded();
    } catch (error) {
      message.error(standData ? 'Error al actualizar el stand' : 'Error al agregar el stand');
      console.error('Error submitting stand:', error);
    } finally {
      setSubmitting(false);
    }
  };
  
  return (
    <Form form={form} onFinish={handleSubmit} initialValues={standData}>
      <Form.Item
        label="Nombre"
        name="name"
        rules={[{ required: true, message: 'Por favor, ingrese el nombre del stand' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Logo">
        <Upload
          name="logo"
          beforeUpload={beforeUpload}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>Subir logo</Button>
        </Upload>
        {logoFile && <img src={URL.createObjectURL(logoFile)} alt="Logo" style={{ width: '50px', height: 'auto', marginLeft: '10px' }} />}
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={submitting}>
          {standData ? 'Actualizar' : 'Agregar'} Stand
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddStandForm;
