import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Tag } from 'antd';
import { API_URL } from '../config';

const RegistrosTable = () => {
  const [registros, setRegistros] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [search, setSearch] = useState('');

  useEffect(() => {
    const fetchRegistros = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/registros`, { params: { page: pagination.current, pageSize: pagination.pageSize, search } });
        setRegistros(response.data.data);
        setPagination({
          ...pagination,
          total: response.data.total,
        });
      } catch (error) {
        console.error('Error fetching registros:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRegistros();
  }, [pagination.current, pagination.pageSize, search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const columns = [
    {
      title: 'Folio',
      dataIndex: 'folio',
      key: 'folio',
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Apellido Paterno',
      dataIndex: 'ap_paterno',
      key: 'ap_paterno',
    },
    {
      title: 'Apellido Materno',
      dataIndex: 'ap_materno',
      key: 'ap_materno',
    },
    {
      title: 'Empresa',
      dataIndex: 'empresa',
      key: 'empresa',
    },
    {
      title: 'Escaneado',
      dataIndex: 'scans_count',
      key: 'scans_count',
      render: (text) => `${text} veces`,
    },
    {
      title: 'Stands Escaneados',
      dataIndex: 'scanned_stands',
      key: 'scanned_stands',
      render: (stands) => (
        <>
        {stands?.split(',').map((stand, index) => (
          <Tag key={index} color="blue">
            {stand}
          </Tag>
        ))}
      </>
      ),
    },
  ];

  return (
    <div>
      <h1>Registros</h1>
      <Input.Search
        placeholder="Buscar por folio, nombre o apellidos"
        style={{ width: '100%', marginBottom: 16 }}
        value={search}
        onChange={handleSearch}
      />
      <Table
        dataSource={registros}
        columns={columns}
        rowKey="id_registro"
        loading={loading}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default RegistrosTable;
