import React, { useState } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
} from "@ant-design/icons";

import StandsTable from "./components/StandsTable";
import AddStandForm from "./components/AddStandForm";
import StandStats from "./components/StandStats";
import RegistrosTable from "./components/RegistrosTable";
import StandScansTable from "./components/StandScansTable";

const { Header, Content, Sider } = Layout;

const App = () => {
  const [refresh, setRefresh] = useState(false);
  return (
    <Router>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider collapsible>
          <div className="logo" />
          <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
            <Menu.Item key="1" icon={<PieChartOutlined />}>
              <Link to="/">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<DesktopOutlined />}>
              <Link to="/stands">Stands</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<FileOutlined />}>
              <Link to="/registros">Registros</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<DesktopOutlined />}>
              <Link to="/stand-scans">Stands y Escaneos</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }} />
          <Content style={{ margin: "0 16px" }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 360 }}
            >
              <Routes>
                <Route
                  path="/"
                  element={<div>{/* Add Dashboard content here */}</div>}
                />
                <Route
                  path="/stands"
                  element={
                    <>
                      <AddStandForm onStandAdded={() => setRefresh(!refresh)} />
                      <StandsTable refresh={refresh} setRefresh={setRefresh} />
                    </>
                  }
                />
                <Route path="/stands/:id/stats" element={<StandStats />} />
                <Route path="/registros" element={<RegistrosTable />} />
                <Route path="/stand-scans" element={<StandScansTable />} />
              </Routes>
            </div>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
};

export default App;
