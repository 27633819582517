import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'antd';

import { API_URL } from "../config";

const StandStats = () => {
  const { id: standId } = useParams();
  const [stand, setStand] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch stand information and statistics from the API
    const fetchStandStats = async () => {
      try {
        // Replace this API call with the correct endpoint
        const response = await fetch(`${API_URL}/standStats/${standId}`);
        const data = await response.json();
        setStand(data);
      } catch (error) {
        console.error('Error fetching stand statistics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStandStats();
  }, [standId]);

  if (loading) {
    return <p>Cargando información del stand...</p>;
  }

  if (!stand) {
    return <p>No se encontró la información del stand.</p>;
  }

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl mb-4">Estadísticas para {stand.name}</h1>
      <Card title="Total de escaneos" style={{ width: 300, marginRight: 16 }}>
        {/* Replace with the actual number of total scans */}
        <p>Escaneos totales: 100</p>
      </Card>
      <Card title="Escaneos únicos" style={{ width: 300, marginRight: 16 }}>
        {/* Replace with the actual number of unique scans */}
        <p>Escaneos únicos: 80</p>
      </Card>
      <Card title="Otra información relevante" style={{ width: 300 }}>
        {/* Replace with other relevant data */}
        <p>Otros datos: Ejemplo</p>
      </Card>
    </div>
  );
};

export default StandStats;
