import React, { useState, useEffect } from "react";
import { Table, Button, Modal, message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../config";
import AddStandForm from "./AddStandForm";

const StandsTable = ({ refresh, setRefresh }) => {
  const [stands, setStands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [standToEdit, setStandToEdit] = useState(null);

  const navigate = useNavigate();

  const handleStats = (record) => {
    navigate(`/stands/${record.id}/stats`)
  };

  useEffect(() => {
    const fetchStands = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/stands`);
        setStands(response.data);
      } catch (error) {
        console.error("Error fetching stands:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStands();
  }, [refresh]);

  const handleEdit = (record) => {
    setStandToEdit(record);
    setEditModalVisible(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`${API_URL}/stands/${id}`);
      setStands(stands.filter((stand) => stand.id !== id));
      message.success("Stand eliminado correctamente");
    } catch (error) {
      message.error("Error al eliminar el stand");
      console.error("Error deleting stand:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditModalOk = () => {
    setEditModalVisible(false);
    setRefresh(!refresh);
  };

  const handleEditModalCancel = () => {
    setEditModalVisible(false);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      render: (logo) => (
        <img
          src={`${API_URL}/uploads/stands/${logo}`}
          alt="Stand logo"
          style={{ width: "50px", height: "auto" }}
        />
      ),
    },
    {
      title: "Acciones",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            onClick={() => handleStats(record)}
            type="primary"
            style={{ marginRight: 8 }}
          >
            Estadísticas
          </Button>
          <Button
            onClick={() => handleEdit(record)}
            type="primary"
            style={{ marginRight: 8 }}
          >
            Editar
          </Button>
          <Button onClick={() => handleDelete(record.id)} danger>
            Eliminar
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={stands}
        columns={columns}
        rowKey="id"
        loading={loading}
      />
      <Modal
        title="Editar Stand"
        visible={editModalVisible}
        onCancel={handleEditModalCancel}
        footer={null}
      >
        {standToEdit && (
          <AddStandForm
            key={standToEdit.id}
            onStandAdded={handleEditModalOk}
            standData={{
              name: standToEdit.name,
              logo: standToEdit.logo,
              id: standToEdit.id,
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default StandsTable;
